<template>
  <ChartWrapper :title="title" :options="bubbleChartOptions" :series="yData">
    <template #header>
      <slot name="header"></slot>
    </template>
  </ChartWrapper>
</template>

<script>
import { mapState } from "vuex";
import { merge } from "lodash-es";
import ChartWrapper from "./components/ChartWrapper.vue";

export default {
  name: "BubbleChart",

  components: { ChartWrapper },

  props: {
    title: {
      type: String,
      required: true,
    },

    xData: {
      type: Array,
      required: true,
    },

    yData: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      bubbleChartOptions: {
        chart: {
          type: "bubble",
        },
        labels: this.xData,
      },
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        merge(this.bubbleChartOptions, this.options);
      },
    },

    // darkTheme() {
    //   this.bubbleChartOptions.stroke.colors = this.darkTheme
    //     ? ["#1d2129"]
    //     : ["#ffffff"];
    // },
  },
};
</script>

<style lang="scss" scoped></style>
