<template>
  <div id="chart-wrapper">
    <div v-if="!dashboardView" class="header">
      <div class="title">{{ title }}</div>

      <q-space />

      <slot name="header"></slot>
    </div>

    <div ref="chart" class="chart"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { merge } from "lodash-es";

import ApexCharts from "apexcharts";

export default {
  name: "ChartWrapper",

  props: {
    title: {
      type: String,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },

    series: {
      type: Array,
      default: () => [],
    },

    dashboardView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chartRef: "",
      globalOptions: {
        chart: {
          fontFamily: "Poppins",
          parentHeightOffset: 0,
          toolbar: {
            tools: {
              selection: false,
              zoom: false,
              zoomin:
                '<i class="q-icon text-gray eva eva-plus-circle-outline" style="font-size: 20px;"></i>',
              zoomout:
                '<i class="q-icon text-gray eva eva-minus-circle-outline" style="font-size: 20px;"></i>',
              pan: '<i class="q-icon text-gray eva eva-move-outline" style="font-size: 20px;"></i>',
              reset:
                '<i class="q-icon text-gray eva eva-refresh-outline" style="font-size: 20px;"></i>',
              download:
                '<i class="q-icon text-gray eva eva-download-outline" style="font-size: 20px;"></i>',
              // customIcons: [
              //   {
              //     icon: '<i class="q-icon text-gray eva eva-expand" style="font-size: 20px;"></i>',
              //     index: 1,
              //     title: "Maximize",
              //     click: function (chart, options, e) {
              //       console.log(chart, options, e);
              //     },
              //   },
              // ],
            },
            export: {
              csv: {
                filename: this.title,
              },
              svg: {
                filename: this.title,
              },
              png: {
                filename: this.title,
              },
            },
          },
          background: "#ffffff",
        },
        grid: {
          padding: {
            top: 16,
            bottom: 16,
          },
          borderColor: "#e2e8f0",
        },
        colors: [
          "#4a89dd",
          "#36bd9a",
          "#f7ba43",
          "#e9573e",
          "#977bdd",
          "#d870ad",
          "#8cc051",
        ],
        dataLabels: {
          style: {
            fontWeight: "500",
            fontSize: "14px",
          },
        },
        legend: {
          fontWeight: "500",
        },
        markers: {
          size: 6,
          strokeWidth: 0,
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
        },
        stroke: {
          curve: "straight",
        },
        plotOptions: {
          boxPlot: {
            colors: {
              upper: "#4a89dd",
              lower: "#36bd9a",
            },
          },
        },
        series: [],
      },
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  watch: {
    options: {
      deep: true,
      handler() {
        merge(this.globalOptions, this.options);
        this.chartRef.updateOptions(this.globalOptions);
      },
    },

    series: {
      deep: true,
      handler() {
        this.chartRef.updateSeries(this.series);
      },
    },

    darkTheme() {
      this.globalOptions.chart.background = this.darkTheme
        ? "#1d2129"
        : "#ffffff";
      this.globalOptions.grid.borderColor = this.darkTheme
        ? "#334155"
        : "#e2e8f0";
      this.globalOptions.colors = this.darkTheme
        ? [
            "#5c9dec",
            "#48ceaf",
            "#fecf55",
            "#fa6f52",
            "#ac92ed",
            "#ec87bf",
            "#a1d568",
          ]
        : [
            "#4a89dd",
            "#36bd9a",
            "#f7ba43",
            "#e9573e",
            "#977bdd",
            "#d870ad",
            "#8cc051",
          ];
      this.globalOptions.plotOptions = {
        boxPlot: {
          colors: {
            upper: this.darkTheme ? "#5c9dec" : "#4a89dd",
            lower: this.darkTheme ? "#48ceaf" : "#36bd9a",
          },
        },
      };

      this.chartRef.updateOptions(this.globalOptions);
    },
  },

  mounted() {
    merge(this.globalOptions, this.options);
    this.globalOptions.series = this.series;

    this.chartRef = new ApexCharts(this.$refs.chart, this.globalOptions);
    this.chartRef.render();
  },
};
</script>

<style lang="scss" scoped>
#chart-wrapper {
  background-color: var(--component-bg-color);
  border-radius: 10px;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    // padding: 16px 0px;
    // margin: 0px 16px;
    padding: 10px;
    border-bottom: 1px solid var(--divider-color);

    .title {
      font-weight: 600;
    }
  }

  // .chart {
  //   padding: 16px;
  // }
}
</style>
