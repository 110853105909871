<template>
  <ChartWrapper
    :dashboard-view="dashboardView"
    :title="title"
    :options="pieChartOptions"
    :series="yData"
  >
    <template #header>
      <slot name="header">
        <template v-if="!dashboardView">
          <BaseActionButton
            icon="mdi-pin"
            :color="dashboard ? 'secondary' : 'gray'"
            no-border
            is-flat
            size="20px"
            rotate="rotate-45"
            @click="dashboard ? $emit('isDelete') : $emit('pin')"
          />
        </template>
      </slot>
    </template>
  </ChartWrapper>
</template>

<script>
import { mapState } from "vuex";
import { merge } from "lodash-es";
import ChartWrapper from "./components/ChartWrapper.vue";

export default {
  name: "PieChart",

  components: { ChartWrapper },

  props: {
    title: {
      type: String,
      required: true,
    },

    xData: {
      type: Array,
      required: true,
    },

    yData: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },

    dashboard: {
      type: Boolean,
      default: false,
    },

    dashboardView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pieChartOptions: {
        chart: {
          type: "pie",
        },
        labels: this.xData,
        stroke: {
          colors: ["#ffffff"],
        },
      },
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        merge(this.pieChartOptions, this.options);
      },
    },

    darkTheme() {
      this.pieChartOptions.stroke.colors = this.darkTheme
        ? ["#1d2129"]
        : ["#ffffff"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
