<template>
  <AppLayout>
    <div id="charts">
      <div class="row q-col-gutter-md">
        <!-- bar chart - vertical -->

        <div class="col-6">
          <BarChart
            title="Bar Chart - Vertical"
            :x-data="barChartxData"
            :y-data="barChartyData"
          >
            <template #header>
              <div class="row">
                <BaseButton
                  label="add"
                  class="q-mr-sm"
                  @click="addBarChartData"
                />
                <BaseButton
                  label="remove"
                  color="secondary"
                  @click="removeBarChartData"
                />
              </div>
            </template>
          </BarChart>
        </div>

        <!-- ... -->

        <!-- bar chart - horizontal -->

        <div class="col-6">
          <BarChart
            title="Bar Chart - Horizontal"
            is-horizontal
            :x-data="barChartxData"
            :y-data="barChartyData"
          >
            <template #header>
              <div class="row">
                <BaseButton
                  label="add"
                  class="q-mr-sm"
                  @click="addBarChartData"
                />
                <BaseButton
                  label="remove"
                  color="secondary"
                  @click="removeBarChartData"
                />
              </div>
            </template>
          </BarChart>
        </div>

        <!-- ... -->

        <!-- line chart -->

        <div class="col-6">
          <LineChart
            title="Line Chart"
            :x-data="lineChartxData"
            :y-data="lineChartyData"
          >
            <template #header>
              <div class="row">
                <BaseButton
                  label="add"
                  class="q-mr-sm"
                  @click="addLineChartData"
                />
                <BaseButton
                  label="remove"
                  color="secondary"
                  @click="removeLineChartData"
                />
              </div>
            </template>
          </LineChart>
        </div>

        <!-- ... -->

        <!-- step chart -->

        <div class="col-6">
          <StepChart
            title="Step Chart"
            :x-data="lineChartxData"
            :y-data="lineChartyData"
          >
            <template #header>
              <div class="row">
                <BaseButton
                  label="add"
                  class="q-mr-sm"
                  @click="addLineChartData"
                />
                <BaseButton
                  label="remove"
                  color="secondary"
                  @click="removeLineChartData"
                />
              </div>
            </template>
          </StepChart>
        </div>

        <!-- ... -->

        <!-- stack chart - vertical -->

        <div class="col-6">
          <BarChart
            title="Stack Chart - Vertical"
            is-stacked
            :x-data="barChartxData"
            :y-data="barChartyData"
          >
            <template #header>
              <div class="row">
                <BaseButton
                  label="add"
                  class="q-mr-sm"
                  @click="addBarChartData"
                />
                <BaseButton
                  label="remove"
                  color="secondary"
                  @click="removeBarChartData"
                />
              </div>
            </template>
          </BarChart>
        </div>

        <!-- ... -->

        <!-- stack chart - horizontal -->

        <div class="col-6">
          <BarChart
            title="Stack Chart - Horizontal"
            is-stacked
            is-horizontal
            :x-data="barChartxData"
            :y-data="barChartyData"
          >
            <template #header>
              <div class="row">
                <BaseButton
                  label="add"
                  class="q-mr-sm"
                  @click="addBarChartData"
                />
                <BaseButton
                  label="remove"
                  color="secondary"
                  @click="removeBarChartData"
                />
              </div>
            </template>
          </BarChart>
        </div>

        <!-- ... -->

        <!-- area chart -->

        <div class="col-6">
          <AreaChart
            title="Area Chart"
            :x-data="lineChartxData"
            :y-data="lineChartyData"
          >
            <template #header>
              <div class="row">
                <BaseButton
                  label="add"
                  class="q-mr-sm"
                  @click="addLineChartData"
                />
                <BaseButton
                  label="remove"
                  color="secondary"
                  @click="removeLineChartData"
                />
              </div>
            </template>
          </AreaChart>
        </div>

        <!-- ... -->

        <!-- pie chart -->

        <div class="col-6">
          <PieChart
            title="Pie Chart"
            :x-data="pieChartxData"
            :y-data="pieChartyData"
          />
        </div>

        <!-- ... -->

        <!-- donut chart -->

        <div class="col-6">
          <DonutChart
            title="Donut Chart"
            :x-data="pieChartxData"
            :y-data="pieChartyData"
          />
        </div>

        <!-- ... -->

        <!-- radar chart -->

        <div class="col-6">
          <RadarChart
            title="Radar Chart"
            :x-data="radarChartxData"
            :y-data="radarChartyData"
          />
        </div>

        <!-- ... -->

        <!-- gauge chart -->

        <div class="col-6">
          <GaugeChart
            title="Gauge Chart"
            :x-data="gaugeChartxData"
            :y-data="gaugeChartyData"
          />
        </div>

        <!-- ... -->

        <!-- radial bar chart -->

        <div class="col-6">
          <RadialBarChart
            title="Radial Bar Chart"
            :x-data="radialBarChartxData"
            :y-data="radialBarChartyData"
          />
        </div>

        <!-- ... -->

        <!-- bubble chart -->

        <div class="col-6">
          <BubbleChart
            title="Bubble Chart"
            :x-data="bubbleChartxData"
            :y-data="bubbleChartyData"
          />
        </div>

        <!-- ... -->

        <!-- box plot chart - vertical -->

        <div class="col-6">
          <BoxPlotChart
            title="Box Plot Chart - Vertical"
            :xy-data="boxPlotChartxyData"
          />
        </div>

        <!-- ... -->

        <!-- box plot chart - horizontal -->

        <div class="col-6">
          <BoxPlotChart
            title="Box Plot Chart - Horizontal"
            is-horizontal
            :xy-data="boxPlotChartxyData"
          />
        </div>

        <!-- ... -->

        <!-- candle stick chart -->

        <div class="col-6">
          <CandleStickChart
            title="Candle Stick Chart"
            :xy-data="candleStickChartxyData"
          />
        </div>

        <!-- ... -->

        <!-- range chart - vertical -->

        <div class="col-6">
          <RangeChart
            title="Range Chart - Vertical"
            :xy-data="rangeChartxyData"
          />
        </div>

        <!-- ... -->

        <!-- range chart - horizontal -->

        <div class="col-6">
          <RangeChart
            title="Range Chart - Horizontal"
            is-horizontal
            :xy-data="rangeChartxyData"
          />
        </div>

        <!-- ... -->

        <!-- heat map chart -->

        <div class="col-6">
          <HeatMapChart title="Heat Map Chart" :xy-data="heatMapChartxyData" />
        </div>

        <!-- ... -->

        <!-- tree map chart -->

        <div class="col-6">
          <TreeMapChart title="Tree Map Chart" :xy-data="treeMapChartxyData" />
        </div>

        <!-- ... -->
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import BarChart from "@/components/common/charts/BarChart.vue";
import LineChart from "@/components/common/charts/LineChart.vue";
import StepChart from "@/components/common/charts/StepChart.vue";
import AreaChart from "@/components/common/charts/AreaChart.vue";
import PieChart from "@/components/common/charts/PieChart.vue";
import DonutChart from "@/components/common/charts/DonutChart.vue";
import RadarChart from "@/components/common/charts/RadarChart.vue";
import GaugeChart from "@/components/common/charts/GaugeChart.vue";
import RadialBarChart from "@/components/common/charts/RadialBarChart.vue";
import BubbleChart from "@/components/common/charts/BubbleChart.vue";
import BoxPlotChart from "@/components/common/charts/BoxPlotChart.vue";
import CandleStickChart from "@/components/common/charts/CandleStickChart.vue";
import RangeChart from "@/components/common/charts/RangeChart.vue";
import HeatMapChart from "@/components/common/charts/HeatMapChart.vue";
import TreeMapChart from "@/components/common/charts/TreeMapChart.vue";

export default {
  name: "Charts",

  components: {
    AppLayout,
    BarChart,
    LineChart,
    StepChart,
    AreaChart,
    PieChart,
    DonutChart,
    RadarChart,
    GaugeChart,
    RadialBarChart,
    BubbleChart,
    BoxPlotChart,
    CandleStickChart,
    RangeChart,
    HeatMapChart,
    TreeMapChart,
  },

  data() {
    return {
      barChartxData: [2019, 2020, 2021, 2022],
      barChartyData: [
        {
          name: "Series 1",
          data: [34, 19, 44, 42],
        },
        {
          name: "Series 2",
          data: [12, 39, 52, 36],
        },
      ],
      lineChartxData: [2019, 2020, 2021, 2022],
      lineChartyData: [
        {
          name: "Series 1",
          data: [34, 19, 44, 42],
        },
        {
          name: "Series 2",
          data: [12, 39, 52, 36],
        },
      ],
      pieChartxData: [
        "Apple",
        "Mango",
        "Orange",
        "Watermelon",
        "Grapes",
        "Pineapple",
      ],
      pieChartyData: [44, 55, 13, 33, 38, 9],
      boxPlotChartxyData: [
        {
          data: [
            {
              x: "Series 1",
              y: [40, 51.98, 56.29, 59.59, 63.85],
            },
            {
              x: "Series 2",
              y: [43.66, 44.99, 51.35, 52.95, 59.42],
            },
            {
              x: "Series 3",
              y: [52.76, 57.35, 59.15, 63.03, 67.98],
            },
            {
              x: "Series 4",
              y: [40.3, 51.98, 56.29, 59.59, 63.85],
            },
            {
              x: "Series 5",
              y: [43.66, 44.99, 51.35, 52.95, 59.42],
            },
          ],
        },
      ],
      candleStickChartxyData: [
        {
          data: [
            {
              x: "2022-04-13",
              y: [53.66, 54.99, 51.59, 53.85],
            },
            {
              x: "2022-04-14",
              y: [53.66, 54.99, 51.35, 52.95],
            },
            {
              x: "2022-04-15",
              y: [51.98, 56.29, 51.59, 53.85],
            },
            {
              x: "2022-04-16",
              y: [53.66, 54.99, 51.35, 53.42],
            },
            {
              x: "2022-04-17",
              y: [52.76, 55.35, 52.15, 55.03],
            },
          ],
        },
      ],
      rangeChartxyData: [
        {
          name: "Cricket",
          data: [
            {
              x: "Team A",
              y: [65, 96],
            },
            {
              x: "Team B",
              y: [55, 78],
            },
            {
              x: "Team C",
              y: [65, 96],
            },
          ],
        },
        {
          name: "Football",
          data: [
            {
              x: "Team A",
              y: [25, 88],
            },
            {
              x: "Team B",
              y: [35, 88],
            },
            {
              x: "Team C",
              y: [75, 85],
            },
          ],
        },
      ],
      heatMapChartxyData: [
        {
          name: "Cricket",
          data: [
            {
              x: "2019",
              y: 42,
            },
            {
              x: "2020",
              y: 35,
            },
            {
              x: "2021",
              y: 50,
            },
            {
              x: "2022",
              y: 29,
            },
          ],
        },
        {
          name: "Football",
          data: [
            {
              x: "2019",
              y: 50,
            },
            {
              x: "2020",
              y: 25,
            },
            {
              x: "2021",
              y: 40,
            },
            {
              x: "2022",
              y: 45,
            },
          ],
        },
      ],
      treeMapChartxyData: [
        {
          name: "Population",
          data: [
            {
              x: "New Delhi",
              y: 218,
            },
            {
              x: "Kolkata",
              y: 149,
            },
            {
              x: "Mumbai",
              y: 184,
            },
            {
              x: "Ahmedabad",
              y: 55,
            },
            {
              x: "Bangaluru",
              y: 84,
            },
            {
              x: "Pune",
              y: 31,
            },
            {
              x: "Chennai",
              y: 70,
            },
          ],
        },
      ],
      radarChartxData: ["April", "May", "June", "July", "August", "September"],
      radarChartyData: [
        {
          name: "Cricket",
          data: [26, 21, 20, 6, 8, 15],
        },
        {
          name: "Football",
          data: [45, 52, 38, 24, 33, 10],
        },
      ],
      gaugeChartxData: ["Progress"],
      gaugeChartyData: [72],
      radialBarChartxData: ["Apples", "Oranges", "Bananas", "Berries"],
      radialBarChartyData: [44, 55, 67, 83],
      bubbleChartxData: [2018, 2019, 2020, 2021, 2022],
      bubbleChartyData: [
        {
          name: "Cricket",
          data: [44, 55, 13, 33, 38, 9],
        },
        {
          name: "Football",
          data: [12, 39, 52, 36, 44],
        },
      ],
    };
  },

  methods: {
    addBarChartData() {
      if (this.barChartyData.length < 5) {
        this.barChartyData.push({
          name: `Series ${this.barChartyData.length + 1}`,
          data: [
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
          ],
        });
      }
    },

    removeBarChartData() {
      if (this.barChartyData.length > 1) {
        this.barChartyData.pop();
      }
    },

    addLineChartData() {
      if (this.lineChartyData.length < 5) {
        this.lineChartyData.push({
          name: `Series ${this.lineChartyData.length + 1}`,
          data: [
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
            Math.floor(Math.random() * 100),
          ],
        });
      }
    },

    removeLineChartData() {
      if (this.lineChartyData.length > 1) {
        this.lineChartyData.pop();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
