<template>
  <ChartWrapper :title="title" :options="gaugeChartOptions" :series="yData">
    <template #header>
      <slot name="header"></slot>
    </template>
  </ChartWrapper>
</template>

<script>
import { mapState } from "vuex";
import { merge } from "lodash-es";
import ChartWrapper from "./components/ChartWrapper.vue";

export default {
  name: "GaugeChart",

  components: { ChartWrapper },

  props: {
    title: {
      type: String,
      required: true,
    },

    xData: {
      type: Array,
      required: true,
    },

    yData: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      gaugeChartOptions: {
        chart: {
          type: "radialBar",
        },
        labels: this.xData,
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e2e8f0",
            },
            dataLabels: {
              name: { show: true },
              value: { show: true, color: "#1e293b", fontWeight: 600 },
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        merge(this.gaugeChartOptions, this.options);
      },
    },

    darkTheme() {
      this.gaugeChartOptions.plotOptions.radialBar.track.background = this
        .darkTheme
        ? "#334155"
        : "#e2e8f0";
      this.gaugeChartOptions.plotOptions.radialBar.dataLabels.value.color = this
        .darkTheme
        ? "#cbd5e1"
        : "#1e293b";
    },
  },
};
</script>

<style lang="scss" scoped></style>
