<template>
  <ChartWrapper
    :title="title"
    :options="candleStickChartOptions"
    :series="xyData"
  >
    <template #header>
      <slot name="header"></slot>
    </template>
  </ChartWrapper>
</template>

<script>
import { merge } from "lodash-es";
import { mapState } from "vuex";
import ChartWrapper from "./components/ChartWrapper.vue";

export default {
  name: "CandleStickChart",

  components: { ChartWrapper },

  props: {
    title: {
      type: String,
      required: true,
    },

    xyData: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      candleStickChartOptions: {
        chart: {
          type: "candlestick",
        },
        plotOptions: {
          candlestick: {
            colors: {
              upward: "#4a89dd",
              downward: "#36bd9a",
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        merge(this.candleStickChartOptions, this.options);
      },
    },

    darkTheme() {
      this.candleStickChartOptions.plotOptions.candlestick.colors = {
        upward: this.darkTheme ? "#5c9dec" : "#4a89dd",
        downward: this.darkTheme ? "#48ceaf" : "#36bd9a",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
