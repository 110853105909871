<template>
  <ChartWrapper :title="title" :options="boxPlotChartOptions" :series="xyData">
    <template #header>
      <slot name="header"></slot>
    </template>
  </ChartWrapper>
</template>

<script>
import { merge } from "lodash-es";
import { mapState } from "vuex";
import ChartWrapper from "./components/ChartWrapper.vue";

export default {
  name: "BoxPlotChart",

  components: { ChartWrapper },

  props: {
    title: {
      type: String,
      required: true,
    },

    xyData: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },

    isHorizontal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      boxPlotChartOptions: {
        chart: {
          type: "boxPlot",
        },
        plotOptions: {
          bar: {
            horizontal: this.isHorizontal,
          },
          boxPlot: {
            colors: {
              upper: "#4a89dd",
              lower: "#36bd9a",
            },
          },
        },
        stroke: {
          colors: ["#64748b"],
        },
      },
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        merge(this.boxPlotChartOptions, this.options);
      },
    },

    darkTheme() {
      this.boxPlotChartOptions.stroke.colors = this.darkTheme
        ? ["#94a3b8"]
        : ["#64748b"];
      this.boxPlotChartOptions.plotOptions.boxPlot.colors = {
        upper: this.darkTheme ? "#5c9dec" : "#4a89dd",
        lower: this.darkTheme ? "#48ceaf" : "#36bd9a",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
