<template>
  <ChartWrapper
    :dashboard-view="dashboardView"
    :title="title"
    :options="barChartOptions"
    :series="yData"
  >
    <template #header>
      <slot name="header">
        <template v-if="!dashboardView">
          <BaseActionButton
            icon="mdi-pin"
            :color="dashboard ? 'secondary' : 'gray'"
            no-border
            is-flat
            size="20px"
            rotate="rotate-45"
            @click="dashboard ? $emit('isDelete') : $emit('pin')"
          />
        </template>
      </slot>
    </template>
  </ChartWrapper>
</template>

<script>
import { merge } from "lodash-es";
import ChartWrapper from "./components/ChartWrapper.vue";

export default {
  name: "BarChart",

  components: { ChartWrapper },

  props: {
    title: {
      type: String,
      required: true,
    },

    isHorizontal: {
      type: Boolean,
      default: false,
    },

    isStacked: {
      type: Boolean,
      default: false,
    },

    xData: {
      type: Array,
      required: true,
    },

    yData: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },

    dashboard: {
      type: Boolean,
      default: false,
    },

    dashboardView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      barChartOptions: {
        chart: {
          type: "bar",
          barHeight: "100%",
          stacked: this.isStacked,
        },
        xaxis: {
          categories: this.xData,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: this.isHorizontal,
          },
        },
      },
    };
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        merge(this.barChartOptions, this.options);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
