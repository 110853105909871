<template>
  <ChartWrapper :title="title" :options="radarChartOptions" :series="yData">
    <template #header>
      <slot name="header"></slot>
    </template>
  </ChartWrapper>
</template>

<script>
import { mapState } from "vuex";
import { merge } from "lodash-es";
import ChartWrapper from "./components/ChartWrapper.vue";

export default {
  name: "RadarChart",

  components: { ChartWrapper },

  props: {
    title: {
      type: String,
      required: true,
    },

    xData: {
      type: Array,
      required: true,
    },

    yData: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      radarChartOptions: {
        chart: {
          type: "radar",
        },
        labels: this.xData,
        fill: {
          opacity: 0.5,
        },
        plotOptions: {
          radar: {
            size: 230,
            polygons: {
              strokeColors: "#cbd5e1",
              connectorColors: "#cbd5e1",
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        merge(this.radarChartOptions, this.options);
      },
    },

    darkTheme() {
      this.radarChartOptions.plotOptions.radar.polygons.strokeColors = this
        .darkTheme
        ? "#475569"
        : "#cbd5e1";
      this.radarChartOptions.plotOptions.radar.polygons.connectorColors = this
        .darkTheme
        ? "#475569"
        : "#cbd5e1";
    },
  },
};
</script>

<style lang="scss" scoped></style>
