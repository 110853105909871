<template>
  <ChartWrapper :title="title" :options="lineChartOptions" :series="yData">
    <template #header>
      <slot name="header"></slot>
    </template>
  </ChartWrapper>
</template>

<script>
import { merge } from "lodash-es";
import ChartWrapper from "./components/ChartWrapper.vue";

export default {
  name: "LineChart",

  components: { ChartWrapper },

  props: {
    title: {
      type: String,
      required: true,
    },

    xData: {
      type: Array,
      required: true,
    },

    yData: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      lineChartOptions: {
        type: "line",
        xaxis: {
          categories: this.xData,
        },
      },
    };
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        merge(this.lineChartOptions, this.options);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
